<template>
  <el-dialog
    class="timeslot-left-dialog"
    width="30%"
    title="Заполните данные для оплаты"
    :visible.sync="innerVisible"
    append-to-body
    :before-close="close"
  >
    <el-form
      ref="billForm"
      v-loading="isLoading"
      :model="billForm"
      label-width="300px"
      class="drivers-slots-form"
      label-position="top"
      @submit.native.prevent
    >
      <div v-if="typeBill === 'bill'">
        <div class="inn__label">ИНН плательщика</div>
        <div class="inn__value">
          <el-form-item
            ref="inn"
            v-mask="'############'"
            prop="inn"
            class="inn__value-input"
            :rules="rules"
          >
            <el-input
              v-model="billForm.inn"
              placeholder="Введите ИНН экспортера"
              :disabled="isLoading"
              size="large"
              @focus="$refs.inn.clearValidate()"
              @blur="wasPayerEdited"
            />
          </el-form-item>
          <div class="inn__value-verify">
            <el-button
              v-if="!isPayerVerified"
              type="success"
              @click="verifyPayer"
            >
              Проверить
            </el-button>
          </div>
        </div>
      </div>
      <el-collapse-transition>
        <el-form-item v-if="billForm.name">
          <div class="inn__label">Название организации</div>
          <div class="inn__name">{{ billForm.name }}</div>
        </el-form-item>
      </el-collapse-transition>
      <el-form-item
        style="width: 100%"
        label="Количество таймслотов"
        prop="count"
      >
        <el-input-number
          v-model.number="billForm.count"
          style="width: 100%"
          :min="1"
          type="number"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer-second">
      <iq-button full-width color="second" size="small" @onClick="close">
        Отменить
      </iq-button>
      <iq-button
        size="small"
        :disabled="isDisableSubmit"
        @onClick="submitForm()"
      >
        {{ typeBill === 'bill' ? 'Сформировать счет' : 'Перейти к оплате' }}
      </iq-button>
    </span>
  </el-dialog>
</template>

<script>
import { downloadRegistry, getLoginToken } from '@/core'
import Api from '@/api'
import IqButton from '@/UI/buttons/IqButton'
const token = getLoginToken()

export default {
  components: { IqButton },
  props: {
    innerVisible: {
      type: Boolean,
      required: true,
    },
    typeBill: {
      type: String,
      required: true,
    },
  },
  data() {
    // проверка наличия юрлица с указанным ИНН
    let validatePayerInnExist = async (rule, value, callback) => {
      this.isLoading = true
      try {
        const { data } = await Api.drivers.getPublicCheckInn(value)

        this.temporaryPayer = data.name
        callback()
      } catch (e) {
        callback(new Error('Указан несуществующий ИНН'))
      } finally {
        this.isLoading = false
      }
    }
    let validatePayerInnLength = (rule, value, callback) => {
      if (value.length === 10 || value.length === 12) {
        callback()
      } else {
        callback(new Error('Неверный формат ИНН'))
      }
    }

    return {
      billForm: { name: '', inn: null, count: 1 },
      confirmDelete: false,
      payer: {
        inn: null,
        id: null,
        verified: false,
      },
      temporaryPayer: null,
      rules: [
        {
          required: true,
          message: 'Пожалуйста, укажите ИНН',
          trigger: 'submit',
        },
        { validator: validatePayerInnLength, trigger: 'submit' },
        { validator: validatePayerInnExist, trigger: 'submit' },
      ],
      isLoading: false,
    }
  },
  computed: {
    isDisableSubmit() {
      return !(this.typeBill === 'online' || this.billForm.name)
    },
    isPayerVerified() {
      return this.payer.verified || false
    },
  },
  methods: {
    // при уходе с инпута экспортера проверяем, изменен ли ИНН, и если да,
    // то меняем параметр необходимости верификации через БД
    wasPayerEdited() {
      if (String(this.payer.inn) !== String(this.billForm.inn)) {
        this.payer.inn = this.billForm.inn
        this.billForm.name = ''
        this.payer.verified = false
      }
    },
    // проверка наличия предприятия по указанному ИНН
    // и сохранение данных, если таковое есть
    verifyPayer() {
      this.$refs.billForm.validateField('inn', errorMessage => {
        if (!errorMessage) {
          this.billForm.name = this.temporaryPayer
          this.payer.verified = true
        }
      })
    },
    submitForm() {
      let path =
        this.typeBill === 'bill'
          ? `https://iq.port-tranzit.ru/api/domain/billing/get-payment-pdf?token=${token}&inn=${this.billForm.inn}&name=${this.billForm.name}&count=${this.billForm.count}`
          : `https://iq.port-tranzit.ru/api/domain/billing/payment-link-redirect?token=${token}&count=${this.billForm.count}`
      let download = this.typeBill === 'bill'

      downloadRegistry({
        path,
        blank: 0,
        download,
      })

      this.$emit('close')
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass">
$bg-modal: rgba(225, 225, 225, 0.82)
$bg-modal-header: #4CA7D8
$white: #fff
.timeslot-left-dialog
  background: $bg-modal

  .el-dialog
    border-radius: 4px!important
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important
    @media(max-width: 1120px)
      width: 50%!important
    @media(max-width: 768px)
      width: 80%!important
    @media(max-width: 480px)
      width: 90%!important
  .el-dialog__body
    padding: 12px 15px!important
  .dialog-footer-second
    display: flex
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
    @media(max-width: 420px)
      flex-wrap: wrap
  .el-button
    @media(max-width: 420px)
      margin-left: 0px!important
      margin-bottom: 10px!important
      margin-top: 10px!important
  .el-dialog__headerbtn
    top: 13px!important
    right: 10px!important
  .el-dialog__close
    font-size: 24px!important
    color: $white!important
  .el-dialog__header
    background: $bg-modal-header!important
    padding: 12px 15px!important
    border-radius: 4px 4px 0px 0px!important
    > span
      font-style: normal!important
      font-weight: 500!important
      font-size: 18px!important
      line-height: 26px!important
      color: $white!important

  .inn__label
    font-size: $fs-14
    line-height: 22px
    font-weight: bold
  .inn__value
    display: flex
    &-input
      width: 300px
    &-verify
      margin-left: 16px
      .el-button
        margin-top: 0 !important
  .inn__name
    font-size: 16px
    color: $grey-font
</style>
